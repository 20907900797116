<template>
  <div>
    <div class="upload-block m-t-20">
      <router-link
        :to="{
          name: 'SastechPackage',
          params: { company: cartData.company, id: cartData.id },
        }"
      >
        <div class="upload-content p-relative">
          <img :src="cartData.image" alt="" />
        </div>
      </router-link>  
      <div class="upload-bottom">
        <p>
          <b>{{ $t("COMMON.PRICE") }}:</b> {{cartData.DISCOUNT_VATPRICE}}
        </p>
        <button 
          class="primary-btn bg-blue add-basket_btn"
          @click='addToCart()'
        >
          <img
            src="/new-assets/icon/shopping-cart-light.svg"
            alt=""
            class="mx5"
          />
          <p>{{ $t('MANAGE_SHANIV.ADD_TO_BASKET') }}</p>
        </button>
        <div class="cart-num">
          <img
            src="/new-assets/icon/plus-circle-solid.svg"
            alt=""
            class="circle-btn plus-circle"
            @click="increase()"
          />
          <input type="text" class="product-num" v-model="productNum" />
          <img
            src="/new-assets/icon/minus-circle-solid.svg"
            alt=""
            class="circle-btn minus-circle"
            @click="decrease()"
          />
        </div>
      </div>
    </div>
    <p class="text-center color-blue3 fs16 weight-700">
      {{cartData.name}}
    </p>
    <p class="text-center color-blue3 fs14 weight-700">
      {{ $t('MANAGE_SHANIV.VALID_UNTIL') }} {{cartData.end_date}}
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ProductCart',
  props: {
    cartData: Object
  },
  data() {
    return {
      productNum: 1,
      tempProduct: {}
    }
  },
  methods: {
    ...mapActions('cartSystem', {
      addProductsToCart: 'addProductsToCart',
    }),
    ...mapActions('alert', {
      error: 'error'
    }),
    increase() {
      this.productNum += 1;
    },
    decrease() {
      if (this.productNum > 1) {
        this.productNum -= 1;
      }
    },
    addToCart() {
      const quantity = this.getTempQuantity();

      const product = this.cartData;

      if (!quantity) {
        this.error(this.$t("SASTECH.PLEASE_SELECT_QUANTITY"));
        return;
      }
      this.tempProduct = this.cartData;
      this.tempProduct.added_quantity = quantity;
      this.tempProduct.added_total_price = this.calculatePackageQuantityPrice({ DISCOUNT_VATPRICE: this.tempProduct.DISCOUNT_VATPRICE, quantity: this.tempProduct.added_quantity });
      this.addProductsToCart({
        product,
        quantity,
        company: product.company,
        type: 'packages'
      });
      this.productNum = 1;

      this.tempProduct.total_price = this.cartTotalDiscountVATPrice;
      this.tempProduct.total_unique_count = this.cartTotalUniqueCount;

      this.$emit('addToCart', this.tempProduct);
    },
    getTempQuantity() {
      return this.productNum || 1;
    },
    getTempToCartPrice({ id, DISCOUNT_VATPRICE }) {
      let tempQuantity = this.getTempQuantity();

      return this.calculatePackageQuantityPrice({ DISCOUNT_VATPRICE: DISCOUNT_VATPRICE, quantity: tempQuantity });
    },
    calculatePackageQuantityPrice({ DISCOUNT_VATPRICE, quantity }) {
      return Math.round((DISCOUNT_VATPRICE * quantity + Number.EPSILON) * 100) / 100;
    }
  },
  computed: {
    ...mapGetters('cartSystem', {
      packagesTotalQuantity: 'packagesTotalQuantity',
      packagesTotalDiscountPrice: 'packagesTotalDiscountPrice',
      packagesTotalDiscountVATPrice: 'packagesTotalDiscountVATPrice',
      cartTotalDiscountVATPrice: 'cartTotalDiscountVATPrice',
      cartTotalUniqueCount: 'cartTotalUniqueCount',
    }),
  }

}
</script>

<style lang="scss" scoped>
.upload-block {
  border: 1px solid #234395;
  border-radius: 7px;
  .upload-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 270px;
    background-color: #fff;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background-size: contain;
    background-position: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .add-basket_btn {
    border-radius: 20px;
    height: 38px;
    width: auto;
    padding: 0 20px;
    font-size: 14px;
  }
  .upload-bottom {
    background-color: #d4e0ff;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    position: relative;
    .cart-num {
      position: relative;
      .circle-btn {
        position: absolute;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
      }
      .plus-circle {
        right: 9px;
      }
      .minus-circle {
        left: 9px;
      }
      input {
        background-color: #fff;
        width: 60px;
        text-align: center;
        margin: 0 20px;
      }
    }
  }
}
</style>